<template>
    <div class="cityE4e">
    <el-container>
      <el-header style="width: 100%; height: 30%;">
          <vheadere class="hidden-sm-and-down"></vheadere>
          <ivheaderae class="hidden-md-and-up"></ivheaderae>
          <div class="top-img">
             <img class="hidden-sm-and-down" width="100%" src="../../assets/images/en/A/E2-topImg.png" alt="">
             <img class="hidden-md-and-up" width="100%" src="../../assets/images/en/A/E2-topImg-m.png" alt="">
             <div class="top-img-top">
               <p>Friendship Cities</p>
             </div>
        </div>
      </el-header>
      <el-main class="ebed">
          <div class="ew ebed-mi">
              <div class="ec-top-foot">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                  <el-breadcrumb-item > <router-link to="/EN">Home</router-link></el-breadcrumb-item>
                  <el-breadcrumb-item to="/FriendshipEN">Friendship Cities</el-breadcrumb-item>
                  <el-breadcrumb-item>{{circle.CITYNAME}}</el-breadcrumb-item>
                </el-breadcrumb>
              </div>
              <div class="hle">
                  <span style="font-weight: 700;">{{ circle.CITYNAME }}</span>
                  <span>  -  {{ circle.COUNTRY }}</span>
              </div>
              <el-row type="flex" justify="space-between" class="wes">
                  <el-col :xs="24" :sm="15" :md="18" :lg="18" :xl="18" class="ft">
                      <div class="top-l">
                          <p>2019-4-15</p>
                          <span> | </span>
                          <p>Friendship Cities</p>
                      </div>
                      <div class="main-l">
                          <div class="main-txt">City information</div>
                          <p>Country：{{circle.COUNTRY}}</p>
                          <p>Name of province/city/district：{{circle.CITYNAME}}</p>
                          <p>Signing date：{{circle.CITYNAME2}}</p>
                      </div>
                      <div class="main-img">
                          <img :src="circle.IMAGE" alt="">
                      </div>
                      <div class="main-f">
                          <div class="main-txt">City  Overview</div>
                          <div v-html="msg"></div>
                      </div>
                  </el-col>
                  <el-col :xs="24" :sm="8" :md="5" :lg="5" :xl="5" class="gt">
                      <div class="top-r">
                          Related Information
                      </div>
                      <div class="main-r">
                        <div class="main-it" v-for="item in xins" :key="item">
                            <div @click="gotoci(item.SEEPENG_ID)">
                                <img :src="item.IMAGE" alt="">
                                <div class="it-t" @click="gotoci">
                                    <span>{{item.CITYNAME}}</span>
                                    <p>{{item.COUNTRY}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="main-it">
                            <img src="" alt="">
                            <div class="it-t">
                                <span></span>
                                <p></p>
                            </div>
                        </div>
                        <gotop></gotop>
                    </div>
                  </el-col>
              </el-row>
          </div>
      </el-main>
      <el-footer>
        <vfootere class="hidden-sm-and-down"></vfootere>
        <ivfootere class="hidden-md-and-up"></ivfootere>
      </el-footer>
    </el-container>
</div>
</template>
<script>
import Gotop from '../../components/gotop'
import { circleD, circleE } from '../../api-en'

export default {
  data () {
    return {
      circle: {},
      ids: {},
      msg: {},
      circleimg: {},
      xins: []
    }
  },
  created () {
    this.ids = this.$route.query.id
  },
  components: {
    Gotop
  },
  mounted () {
    this.gotoes()
    this.gotoCity()
  },
  methods: {
    gotoes () {
      circleD(this.ids).then(res => {
        console.log(res)
        this.circle = res.data[0]
        this.msg = res.data[0].CONTENT
      })
    },
    gotoCity () {
      circleE().then(res => {
        this.circleimg = res.data
        let xin = []
        let arr = Math.floor(Math.random() * this.circleimg.length)
        let n = this.circleimg.splice(arr, 3)[0]
        xin.push(n)
        console.log(xin)
        let arrb = Math.floor(Math.random() * this.circleimg.length)
        //   console.log(arr)
        let nb = this.circleimg.splice(arrb, 3)[0]
        xin.push(nb)
        let arrc = Math.floor(Math.random() * this.circleimg.length)
        //   console.log(arr)
        let nc = this.circleimg.splice(arrc, 3)[0]
        xin.push(nc)
        this.xins = this.xins.concat(xin)
      })
    },
    gotoci (id) {
      this.ids = id
      this.$router.push({ name: `cityE4e`, query: { id: id } })
      this.gotoes()
      this.xins.length = 0
      this.gotoCity()
      //   console.log(13231)
    }
  }
//   watch: {
//     '$route':to
//   },
}
</script>

<style scoped>
.bg-b {
    display: none;
}
.bg-a {
    width: 100%;
}
.ew {
    max-width: 1154px;
    margin: auto;
}
.ew .wes {
    display: flex;
    flex-wrap: wrap;
}
.top-img {
    position: relative;
}
.top-img-top p {
    font-size: 0.6rem;
    font-weight: 600;
}
.top-img-top {
    position: absolute;
    top: 45%;
    left: 5%;
    color: #FFFFFF;
    text-align: left;
}
.top-img-top span {
    font-size: 0.45rem;
    font-weight: 300;
}
.top-img .top-img-top:after {
    content: '';
    position: absolute;
    left: 0;
    top: -16px;
    height: 1px;
    width: 40%;
    background: #FFF;
}
.ew .wes .ft {
    flex: 7;
    margin-right: 1.3rem;
}
.ew .wes .gt {
    flex: 2;
}
.wes .ft .top-l {
    border-bottom: 1px solid rgba(15,183,209,1);
    margin-bottom: 0.6rem;
    height: 0.8rem;
    line-height: 0.8rem;
    font-size: 0.25rem;
    color:rgba(158,158,158,1);
}
.wes .ft .top-l p {
    float: left;
    padding-left: 5px;
}
.ft .top-l span {
    float: left;
    margin: 0 0.2rem;
}
.gt .top-r {
    height: 0.8rem;
    line-height: 0.8rem;
    text-align: left;
    margin-bottom: 0.6rem;
    border-bottom: 1px solid rgba(15,183,209,1);
}
.ew .hle {
    max-width: 510px;
    font-size: 0.55rem;
    color:rgba(15,183,209,1);
    text-align: left;
    margin-top: 0.8rem;
    margin-bottom: 0.2rem;
}
.gt .main-r .main-it {
    margin-bottom: 0.7rem;
    text-align: left;
}
.main-r .main-it img {
    width: 100%;
    height: 3.3rem;
    display: block;
    object-fit: cover;
}
.main-r .main-it .it-t span {
    font-size: 0.45rem;
    color: rgba(15,183,209,1);
}
.main-r .main-it .it-t p {
    font-size: 0.3rem;
}
.main-l .main-txt, .main-f .main-txt {
    max-width: 3rem;
    height: 0.8rem;
    background: rgba(15,183,209,1);
    color: #fff;
    line-height: 0.8rem;
    font-size: 0.35rem;
    margin-left: -0.1rem;
    margin-bottom: 0.45rem;
    text-align: center;
}
.ft .main-l p {
    text-align: left;
    height: 0.6rem;
    font-size: 0.3rem;
}
.main-img {
    margin-top: 0.6rem;
    padding-right: 0.4rem;
    margin-bottom: 0.6rem;
}
.main-img img {
    width: 100%;
}
@media screen and (max-width:769px){
    .gt {
        flex: 100%;
    }
    .ew .wes .ft {
        flex: 100%;
        margin: 0;
        margin-bottom: 0.6rem;
    }
    .gt .top-r {
        font-size: 0.5rem;
        border-bottom: none;
        border-top: 1px solid rgba(15,183,209,1);
        padding-top: 0.8rem;
        height: 1.3rem;
    }
    .main-img p {
        text-align: center;
        line-height: 0.7rem;
        margin-bottom: 0.9rem;
    }
    .main-l p {
        padding-left: 0.2rem;
    }
    .main-img {
        padding-right: 0;
    }
    .main-r {
        display: flex;
        flex-wrap: wrap;
        padding: 0.2rem;
    }
    .main-it {
        flex: 45%;
        margin-right: 0.4rem;
    }
    .main-it span {
        font-size: 0.7rem;
    }
    .it-t span {
        font-size: 0.45rem;
    }
    .main-it:nth-child(2n) {
        margin-right: 0;
    }
}
</style>

<style lang="less">
.cityE4e{
  .el-header {
    padding: 0;
  }
  .el-main {
    margin-top: 0;
    overflow: initial;
    padding: 0;
  }
  .main-f {
    text-align: left;
    font-size: 0.3rem;
  }
  .main-f p {
    margin-bottom: 0.6rem;
  }
  .main-f h4 {
    text-indent: 0;
    margin-top: 1rem;
  }
  .ebed .ebed-mi {
    position: relative;
    padding: 20px;
  }
  .ebed .ebed-mi .ec-top-foot {
    position: absolute;
    top: -47px;
    left: 0;
    background: rgba(0, 182, 205,1);
    min-width: 6.5rem;
    height: 0.76rem;
    align-items: center;
  }
  .ebed .ebed-mi .ec-top-foot .el-breadcrumb {
    line-height: 0.76rem;
    padding-left: 10px;
  }
  .el-breadcrumb__inner a, .el-breadcrumb__inner.is-link {
    color: #fff;
    font-weight: 400;
  }
  .el-breadcrumb__item:last-child .el-breadcrumb__inner,
  .el-breadcrumb__inner a {
    color: #fff;
    font-weight: 400;
  }
  .el-breadcrumb__item .el-breadcrumb__separator {
    color: #fff;
  }
  .el-breadcrumb__inner a:hover,
  .el-breadcrumb__inner.is-link:hover {
    color: #fff600;
  }
  @media screen and (max-width:1057px){
    .ebed .ebed-mi .ec-top-foot {
      display: none;
    }
  }
  @media screen and (max-width:769px){
    .main-f p {
      padding: 0 0.2rem;
    }
    .main-f h4 {
      padding: 0 0.2rem;
    }
  }
}
</style>
